// /billingapi
const GRAVITEE_API_KEY_BILLING = process.env.REACT_APP_GRAVITEE_API_KEY_BILLING ?? '';
// /sso
const GRAVITEE_API_KEY_SSO = process.env.REACT_APP_GRAVITEE_API_KEY_SSO ?? '';
// /user
const GRAVITEE_API_KEY_USER = process.env.REACT_APP_GRAVITEE_API_KEY_USER ?? '';

export const graviteeApiKeys = Object.freeze({
    billingapi: GRAVITEE_API_KEY_BILLING,
    sso: GRAVITEE_API_KEY_SSO,
    user: GRAVITEE_API_KEY_USER,
});

export type GraviteeApiKeyType = keyof typeof graviteeApiKeys;
