import { FC } from 'react';
import { Tab } from '@russpass/ui';
import './styles.sass';

type TabInfo = {
    id: number;
    name: string;
    onClick: () => void;
};

type HistoryTabsProps = {
    tabs: TabInfo[];
    activeTabId: number;
};

const HistoryTabs: FC<HistoryTabsProps> = ({ tabs, activeTabId }) => {
    return (
        <div className="history-tabs">
            <div className="history-tabs__wrap">
                {tabs?.map((tab) => (
                    <Tab
                        tabIndex={tab.id}
                        key={tab.id}
                        isActive={tab.id === activeTabId}
                        label={tab.name}
                        onClick={tab.onClick}
                    />
                ))}
            </div>
        </div>
    );
};

export default HistoryTabs;
