import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import { ServerResponse, Profile, User } from '../../types';
import { mapDate } from '../utils';

// Получение профиля
export const getProfile = async () => {
    const profile: ServerResponse<Profile> = await fetchRequest.get(`${apiService.user}/users/profile`, null, {
        isProtected: true,
    });
    if (profile) return profile.result;
    throw new Error('Не удалось получить данные');
};

export type UserData = {
    userAuthentication: {
        details: {
            userAuthentication: {
                details?: {
                    activation_code: string;
                };
                principal: {
                    user: {
                        lastName: string;
                        firstName: string;
                        middleName: string;
                        org?: {
                            inn: string;
                            name: string;
                        };
                        created: string;
                        id: number;
                    };
                };
            };
        };
    };
};

const mapUserData = (item: UserData) => {
    const data = item.userAuthentication.details.userAuthentication;
    const userData = data.principal.user;
    /* Добавляем соответствующее количество минут, дабы привести к локальной таймзоне */
    /* Для GMT+0300 getTimezoneOffset вернет -180 минут */
    const registrationDate = mapDate(userData.created);
    const user: User = {
        code: data.details?.activation_code ?? '',
        organization: {
            name: userData.org?.name ?? '',
            inn: userData.org?.inn ?? '',
        },
        registrationDate,
        firstName: userData.firstName,
        lastName: userData.lastName,
        middleName: userData.middleName,
        id: userData.id,
    };

    return user;
};

export const getUserData = async (id?: string) => {
    const user: UserData = await fetchRequest.get(`${apiService.user}/users/${id ?? 'me'}`, null, {
        isProtected: true,
    });
    if (user) return mapUserData(user);
    throw new Error('Не удалось получить данные');
};

/* eslint-disable import/no-anonymous-default-export */
export default {
    getProfile,
    getUserData,
};
