import queryString from 'query-string';

import { mapToken } from '../utils';
import { fetchRequest } from './index';
import { authBasicToken, clientId, authSecret, apiService } from './../../config/constants';

export const signIn = async (code: string) => {
    const tokenData = await fetchRequest.post(
        `${apiService.auth}/oauth/token?${queryString.stringify({
            grant_type: 'activation_code',
            client_id: clientId,
            scope: 'read write',
            secret: authSecret,
            activation_code: code,
        })}`,
        {},
        {
            headers: {
                // Для авторизации нам нужно проставить именно такой заголовок
                Authorization: `Basic ${authBasicToken}`,
            },
        }
    );
    if (tokenData) return mapToken(tokenData);
    throw new Error('Не удалось получить данные');
};

/* eslint-disable import/no-anonymous-default-export */
export default {
    signIn,
};
