import { ReactComponent as IconError } from '../../../../../../../../assets/images/icons/ic_cross_circle.svg';
import './styles.sass';

interface INotificationErrorStatusProps {
    onClose: () => void;
    errorCause: string;
    errorDescription?: string;
}

export const NotificationErrorStatus = ({ errorCause, errorDescription, onClose }: INotificationErrorStatusProps) => {
    return (
        <div className="notification-error-wrap">
            <div className="notification-error">
                <div className="notification-error__content">
                    <div className="notification-error-wrap__icon">
                        <IconError />
                    </div>

                    <div className="notification-error__info">
                        <div className="notification-error__info-type">{errorCause}</div>
                        {Boolean(errorDescription) && (
                            <div className="notification-error__info-description">{errorDescription}</div>
                        )}
                    </div>

                    <div className="notification-error__btn-wrap">
                        <button onClick={onClose} type="button" className="notification-error__btn">
                            ОК
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
