import { FC } from 'react';
import { ReactComponent as IconDownload } from '../../../../../../assets/images/icons/ic_download.svg';
import { billingApi } from '../../../../../../lib/api';
import download from 'downloadjs';
import notification from '../../../../../../components/Notification';
import { toast } from 'react-toastify';
import './styles.sass';

const DownloadButton: FC = () => {
    const onClick = async () => {
        try {
            await billingApi.getReport().then((data: Blob | undefined) => {
                data &&
                    download(
                        data,
                        'ticket-use-history.xlsx',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    );
            });
        } catch (err: any) {
            console.error(err);
            if (err?.message === 'RESPONSE_ERROR') {
                notification.error('Что-то пошло не так, попробуйте позже', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }
    };

    return (
        <div className="download-button" onClick={onClick}>
            <IconDownload />
            <div className="download-button__text">
                <span className="download-button__text--mobile-hidden">Скачать</span>
                <span>XLSX</span>
            </div>
        </div>
    );
};

export default DownloadButton;
