import { ReactComponent as IconErrorSticker } from '../../../../../../../../assets/images/icons/ic_error_sticker.svg';
import { phoneCallback } from '../../../../../../../../config/constants';
import './styles.sass';

interface IScreenErrorStatusProps {
    onClose: () => void;
    errorCause: string;
    errorDescription?: string;
}

export const ScreenErrorStatus = ({ errorCause, errorDescription, onClose }: IScreenErrorStatusProps) => {
    return (
        <div className="screen-error">
            <div className="screen-error__stub"></div>

            <div className="screen-error__content">
                <div className="screen-error__icon">
                    <IconErrorSticker />
                </div>

                <div className="screen-error__info">
                    <div className="screen-error__info-type">{errorCause}</div>
                    {Boolean(errorDescription) && (
                        <div className="screen-error__info-description">{errorDescription}</div>
                    )}
                </div>

                <div className="screen-error__btn-wrap">
                    <button onClick={onClose} type="button" className="screen-error__btn">
                        Назад к сканеру
                    </button>
                </div>
            </div>

            <div className="screen-error__helper">
                <div className="screen-error__helper-description">Подскажите клиенту номер поддержки</div>
                <div className="screen-error__helper-phone-wrap">
                    <a href={`tel:${phoneCallback}`} className="screen-error__helper-phone">
                        {phoneCallback}
                    </a>
                </div>
            </div>
        </div>
    );
};
