import './styles.sass';

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active) {
        return (
            <div className="history-chart__tooltip">
                <div className="history-chart__tooltip-label">{label}</div>
                <div className="history-chart__tooltip-data">
                    <span
                        className="history-chart__tooltip-circle"
                        style={{ backgroundColor: payload?.[0]?.color }}
                    ></span>
                    {payload?.[0]?.value}
                </div>
            </div>
        );
    }
    return null;
};

export default CustomTooltip;
