import { ReactComponent as IconSuccess } from '../../../../../../assets/images/icons/ic_check_circle.svg';
import { SuccessTicketStatus } from '../../../../../../types';
import './styles.sass';

type Props = {
    onClose: () => void;
    successData: SuccessTicketStatus | null;
};

const TicketSuccessStatus = ({ onClose, successData }: Props) => {
    return (
        <div className="ticket-status-success">
            <div className="ticket-status-success__icon">
                <IconSuccess />
            </div>
            <div className="ticket-status-success__info">
                <div className="ticket-status-success__info-type">{successData?.tariffName}</div>
                <div className="ticket-status-success__info-description">
                    {successData?.activeSinceTime && (
                        <span className="ticket-status-success__info-description-time">
                            {`${successData?.activeSinceTime}${
                                successData?.activeUntilTime ? '-' + successData?.activeUntilTime : ''
                            }`}
                        </span>
                    )}
                    <span className="ticket-status-success__info-description-product-name">
                        {successData?.productName}
                    </span>
                </div>
            </div>
            <div className="ticket-status-success__btn-wrap">
                <button onClick={onClose} type="button" className="ticket-status-success__btn">
                    ОК
                </button>
            </div>
        </div>
    );
};

export default TicketSuccessStatus;
