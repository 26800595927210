import { FC, useState, useRef, useCallback, RefObject, useEffect } from 'react';
import { RangePicker, RangePickerPropsHandler } from '@russpass/ui';
import { ReactComponent as IconArrowLightLeft } from '../../../../../../assets/images/icons/ic_arrow_light_left.svg';
import { useMediaQuery } from 'react-responsive';
import { MD } from '../../../../../../config/constants';
import { isToday, useClosePopupOver, roundToHour, mobileAndTabletCheck } from '../../../../../../lib/utils';
import classNames from 'classnames';

import './styles.sass';

type RangeValType = {
    from: Date | null;
    to: Date | null;
};

type SelectRangeProps = {
    hide: () => void;
    getData: (params: RangeValType) => void;
};

const SelectRangeDate: FC<SelectRangeProps> = ({ hide, getData }) => {
    const rangePicker = useRef() as RefObject<RangePickerPropsHandler>;
    const rangePickerWrap = useRef<HTMLDivElement | null>(null);
    const isPhoneOrTablet = useMediaQuery({ query: `(max-width: ${MD}px)` }) && mobileAndTabletCheck();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [rangeDate, setRangeDate] = useState<RangeValType | undefined>(undefined);

    const toMonthMobile = new Date();
    toMonthMobile.setMonth(new Date().getMonth());

    const resetRangeDate = () => {
        setRangeDate(undefined);
        rangePicker.current?.resetClick();
    };

    const getRangeData = useCallback(() => {
        rangeDate && getData(rangeDate);
        hide();
    }, [rangeDate, getData, hide]);

    const handleRangeChange = (period: RangeValType | undefined) => {
        if (period?.from && period?.to) {
            let from = period?.from,
                to = period?.to;

            period?.from?.setHours(0, 0, 0, 0);

            if (isToday(to)) {
                to = roundToHour(new Date());
            } else {
                to.setHours(23, 59, 59, 999);
            }

            setRangeDate({
                from,
                to,
            });
        } else {
            setRangeDate(period);
        }
    };

    useEffect(() => {
        isPhoneOrTablet &&
            wrapperRef.current &&
            rangePickerWrap.current &&
            wrapperRef?.current.scrollTo(0, rangePickerWrap.current?.scrollHeight);
    }, [isPhoneOrTablet]);

    useClosePopupOver(wrapperRef, hide);

    return (
        <div className="selected-range" ref={wrapperRef}>
            <div className="selected-range__header">
                <div className="selected-range__header-button-wrap">
                    <button className="selected-range__header-button" onClick={hide}>
                        <IconArrowLightLeft />
                    </button>
                </div>
                <div className="selected-range__header-title">Свой период</div>
                <div className="selected-range__header-button-wrap"></div>
            </div>
            <div ref={rangePickerWrap} className="selected-range__calendar-wrap">
                <RangePicker
                    ref={rangePicker}
                    isMobile={isPhoneOrTablet}
                    numberOfMonths={60}
                    handleChange={handleRangeChange}
                    disabledDays={{ after: new Date() }}
                    enableEmptySave={true}
                    toMonth={isPhoneOrTablet ? toMonthMobile : undefined}
                    fromMonth={isPhoneOrTablet ? new Date() : undefined}
                    value={
                        rangeDate?.from && rangeDate?.to
                            ? {
                                  from: new Date(rangeDate.from),
                                  to: new Date(rangeDate.to),
                              }
                            : undefined
                    }
                    month={new Date()}
                />
            </div>

            <div
                className={classNames(
                    'selected-range__buttons-wrap',
                    !rangeDate && 'selected-range__buttons-wrap__hidden'
                )}
            >
                <div className="selected-range__button-wrap">
                    <button
                        className="selected-range__button selected-range__button--primary"
                        disabled={rangeDate === undefined}
                        onClick={getRangeData}
                    >
                        Выбрать
                    </button>
                </div>
                <div className="selected-range__button-wrap">
                    <button
                        className="selected-range__button selected-range__button--second"
                        disabled={rangeDate === undefined}
                        onClick={resetRangeDate}
                    >
                        Сбросить
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SelectRangeDate;
