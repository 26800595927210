import queryString from 'query-string';

import tokenManager from '../token-manager';
import { apiUrl } from '../../config/constants';
import { signOut, uuidv4 } from '../utils';
import { getGraviteeApiKey } from '../utils/get-gravitee-api-key';

/**
 * Основной метод, который отсылает запросы
 * @param url - ручка
 * @param paramsData - данные запроса
 * @param isRetry - является ли запрос повторным (используется при 401 ошибке)
 */
export async function fetchWithTokenRequest(url: string, paramsData: any, isRetry = false): Promise<any> {
    const { isProtected, urlWithHost, ...params } = paramsData;
    /**
     * Делаем проверку на наличие и валидность токена
     * Если токен отсутствует, то кидаем на логинку
     * В случае просроченности токена пытаемся сразу получить новый
     *  */
    if (isProtected && !(await tokenManager.isTokenValid())) {
        // alert('Не авторизован');
        return signOut();
    }
    const token = tokenManager.getToken();
    let headers = {
        ...params.headers,
        rqid: uuidv4(),
    };

    const graviteeApiKey = getGraviteeApiKey(url);

    if (graviteeApiKey) {
        headers = {
            ...headers,
            'X-Gravitee-Api-Key': graviteeApiKey,
        };
    }

    if (isProtected) {
        headers = {
            ...headers,
            Authorization: `Bearer ${token ? token.accessToken : ''}`,
        };
    }

    let response;

    try {
        response = await fetch(`${urlWithHost ? '' : apiUrl}${url}`, {
            ...params,
            headers,
        });
    } catch (error: any) {
        throw new Error('RESPONSE_ERROR');
    }

    const contentType = response.headers.get('content-type');

    if (response.ok) {
        if (response.status === 204) {
            return null;
        }
        try {
            // Если в body лежит не json, то при response.text() мы получим ошибку, поэтому на свякий случай клонируем ответ сервера
            if (contentType && contentType.indexOf('application/json') !== -1) {
                return await response.clone().json();
            } else if (
                contentType &&
                contentType.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') !== -1
            ) {
                return await response.blob();
            } else {
                return await response.text();
            }
        } catch {
            console.warn('Cannot parse response as json');
            return await response.text();
        }
    } else {
        if (response.status === 503) {
            throw new Error('Server is under maintenance');
        } else if (response.status === 504) {
            throw new Error('RESPONSE_ERROR');
        } else if (response.status >= 400) {
            if (response.status === 401) {
                // Намеренно делаем токен просроченным, чтоб сделать повторный запрос на обновление токена
                tokenManager.overdueToken();

                if (!isProtected) {
                    throw new Error('Запрос требует авторизации');
                } else if (!isRetry && (await tokenManager.isTokenValid())) {
                    // Если токен все-таки смог обновиться (или был корректным), то пробуем повторно сделать запрос
                    return fetchWithTokenRequest(url, paramsData, true);
                }
                return signOut();
            }
            let resp;
            try {
                resp = await response.json();
                // строго говоря вообще сервер не обязан отдавать какие-то описания в json
                // поэтому сильно и не расчитываем на это
                resp = JSON.parse(resp);
            } finally {
                if (typeof resp == 'string') {
                    throw new Error(resp || `Unhadled error. Server status code ${response.status}`);
                }
                throw resp;
            }
        }
    }
}

export class fetchRequest {
    static async get(
        path: string,
        data?: any,
        options: any = {},
        seriailizeOptions: queryString.StringifyOptions = { arrayFormat: 'bracket' }
    ) {
        return await fetchWithTokenRequest(
            `${path}${data ? `?${queryString.stringify(data, seriailizeOptions)}` : ''}`,
            options
        );
    }

    static async post(path: string, data?: any, options: any = {}) {
        return await fetchWithTokenRequest(path, {
            method: 'POST',
            body: JSON.stringify(data),
            ...options,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ...options.headers,
            },
        });
    }

    static async file(path: string, data?: any, options: any = {}) {
        return await fetchWithTokenRequest(path, {
            method: 'POST',
            body: data,
            ...options,
        });
    }

    static async put(path: string, data: any, options: any = {}) {
        return await fetchWithTokenRequest(path, {
            method: 'PUT',
            body: JSON.stringify(data),
            ...options,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ...options.headers,
            },
        });
    }

    static async delete(path: string, options: any = {}) {
        return await fetchWithTokenRequest(path, {
            method: 'DELETE',
            ...options,
        });
    }
}

export { default as authApi } from './auth';
export { default as userApi } from './user';
export { default as billingApi } from './billing';
