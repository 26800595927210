import React, { ReactNode, useEffect, useRef } from 'react';
import { Field, FieldProps } from 'formik';
import { Input } from '@russpass/ui';
import getValue from 'lodash/get';

type FieldInputProps = {
    label: string;
    value?: string;
    hint?: string;
    error?: string;
    name: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    placeholder?: string;
    type?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    autoComplete?: string;
    maxLength?: number;
    renderComponent?: ((props: { [arbitrary: string]: any }) => ReactNode) | undefined;
    errorSettings?: {
        showOnChange: boolean;
    };
};

export type FormGroupProps = {
    id: string;
    ref: HTMLInputElement | any;
    type: string;
    disabled?: boolean;
    value?: string;
    placeholder?: string;
    className: string;
};

const FieldInput: React.FC<FieldInputProps> = ({
    name,
    label,
    placeholder,
    type = 'text',
    disabled,
    autoFocus,
    autoComplete,
    maxLength,
    renderComponent,
    errorSettings,
    onChange,
    onFocus,
    onBlur,
    hint,
}) => {
    const inputRef = useRef<any>(null);
    useEffect(() => {
        if (autoFocus && inputRef && inputRef.current) {
            inputRef.current?.inputElement?.focus?.();
        }
    }, [autoFocus]);

    return (
        <Field name={name}>
            {(fieldProps: FieldProps) => {
                const { field, form } = fieldProps;
                const formGroupProps = {
                    id: field.name,
                    ref: inputRef,
                    type,
                    disabled,
                    value: field.value || '',
                    placeholder,
                    autoComplete,
                    autoFocus,
                    maxLength,
                    onChange: onChange ?? field.onChange,
                    onFocus: onFocus,
                    onBlur: onBlur,
                };

                let error = false;
                // Бывают моменты, когда нужно показывать ошибку при вводе значений и без оглядки на первоначальные данные
                if ((errorSettings && errorSettings.showOnChange) || form.submitCount) {
                    error = !!getValue(form.errors, field.name);
                } else {
                    error =
                        !!getValue(form.errors, field.name) &&
                        !!getValue(form.touched, field.name) &&
                        form.initialValues !== form.values;
                }

                return (
                    <Input
                        inputProps={{ ...field, ...formGroupProps }}
                        label={label}
                        renderComponent={renderComponent}
                        noHover
                        propsRenderComponent={{ ...field, ...formGroupProps }}
                        error={error ? (getValue(form.errors, field.name) as string) : undefined}
                        hint={hint}
                    />
                );
            }}
        </Field>
    );
};

export default FieldInput;
