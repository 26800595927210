import { graviteeApiKeys, GraviteeApiKeyType } from '../../config/gravitee-api-keys';

export const getGraviteeApiKey = (url: string) => {
    const graviteeMatchUrl = url.startsWith('/') ? url.slice(1).split('/')[0] : url.split('/')[0];
    const value = graviteeApiKeys.hasOwnProperty(graviteeMatchUrl)
        ? graviteeApiKeys[graviteeMatchUrl as GraviteeApiKeyType]
        : null;

    return value;
};
