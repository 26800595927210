import { useState, useCallback } from 'react';
import { SuccessTicketStatus, ErrorTicketStatus } from '../../../../types';
import { billingApi } from '../../../../lib/api';
import { mobileAndTabletCheck } from '../../../../lib/utils';
import { MD } from '../../../../config/constants';
import { useMediaQuery } from 'react-responsive';
import moment, { Moment } from 'moment';
import notification from '../../../../components/Notification';
import { toast } from 'react-toastify';
import { ICheckValue } from './types/check-value';
import { CheckTypeEnum } from './enums/check-type';
import { DisplayTypeEnum } from './components/TicketErrorStatus/enums/display-type';
import { plural } from '../../../../lib/utils/plural-ru';

const pluralOfParticipants = ['участник', 'участника', 'участников'];
const groupTicketsName = 'groupTickets';

const useCheck = () => {
    const [lastUsedTicket, setLastUsedTicket] = useState<ICheckValue>({} as ICheckValue);
    const [lastUsedTicketDate, setLastUsedTicketDate] = useState<Moment | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [isInputFocus, setIsInputFocus] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [successData, setSuccessData] = useState<SuccessTicketStatus | null>(null);
    const [errorData, setErrorData] = useState<ErrorTicketStatus | null>(null);
    const isPhoneOrTablet = useMediaQuery({ query: `(max-width: ${MD}px)` }) && mobileAndTabletCheck();

    const resetState = () => {
        setSuccessData(null);
        setErrorData(null);
        setSuccess(false);
        setError(false);
    };

    const check = useCallback(
        async (checkValue: ICheckValue, checkType: CheckTypeEnum, resetForm?: Function, setErrors?: Function) => {
            const { ticketNumber, groupTickets = false } = checkValue;

            try {
                setLoading(true);
                resetState();

                const ticketStatus = await billingApi.setVisit(ticketNumber, groupTickets);
                const { tickets = [] } = ticketStatus;

                if (ticketStatus?.errorCode === 0 && tickets.length > 0) {
                    let successValue = {} as SuccessTicketStatus;

                    const firstTicket = tickets[0];

                    if (groupTickets) {
                        const pluralOfParticipantsWord = plural(pluralOfParticipants, tickets.length);

                        successValue = {
                            tariffName: `Групповой, ${tickets.length} ${pluralOfParticipantsWord}`,
                            productName: firstTicket?.productName || '',
                            activeSinceTime: firstTicket?.activeSinceTime,
                            activeUntilTime: firstTicket?.activeUntilTime,
                        };
                    } else {
                        successValue = {
                            tariffName: firstTicket?.tariffName || '',
                            productName: firstTicket?.productName || '',
                            activeSinceTime: firstTicket?.activeSinceTime,
                            activeUntilTime: firstTicket?.activeUntilTime,
                        };
                    }

                    setLastUsedTicket(checkValue);
                    setLastUsedTicketDate(moment());
                    setSuccessData(successValue);
                    setSuccess(true);
                    resetForm && resetForm();
                }
            } catch (err: any) {
                console.error(err);

                if (err?.message === 'RESPONSE_ERROR') {
                    setErrors
                        ? setErrors({
                              ticket: 'Что-то пошло не так',
                          })
                        : notification.error('Что-то пошло не так, попробуйте позже', {
                              position: toast.POSITION.TOP_CENTER,
                              onOpen: () => {
                                  setError(true);
                              },
                              onClose: () => {
                                  setError(false);
                              },
                          });
                } else {
                    let errorDescription: string | undefined;
                    let errorCause = err?.errorCause;
                    let displayType;

                    if (err?.errorCode === 1009) {
                        if (checkType === CheckTypeEnum.Form && !groupTickets) {
                            errorCause = 'Билет не найден. Если он групповой, отметьте это';
                            displayType = DisplayTypeEnum.Notification;
                        } else if (checkType === CheckTypeEnum.Form && groupTickets) {
                            errorCause = err?.errorCause;
                            errorDescription = 'Введите код снова';
                            displayType = DisplayTypeEnum.Notification;
                        } else {
                            errorDescription = 'Попробуйте заново отсканировать QR-код или ввести номер билета';
                        }
                    }

                    setErrorData({
                        errorCause: errorCause || 'Что-то пошло не так, попробуйте позже',
                        errorDescription,
                        displayType,
                    });
                    setError(true);
                }
            } finally {
                setLoading(false);
            }
        },
        [isPhoneOrTablet]
    );

    const isEqualLastUsedTicket = useCallback(
        ({ ticketNumber, groupTickets }: ICheckValue) =>
            ticketNumber === lastUsedTicket.ticketNumber && groupTickets === lastUsedTicket.groupTickets,
        [lastUsedTicket]
    );

    const handleScan = useCallback(
        async (ticketNumber: string | null) => {
            if (ticketNumber && !isInputFocus && !loading && !error) {
                const now = moment();
                let groupTickets = false;

                const splitTicketNumber = ticketNumber.split(';');
                if (splitTicketNumber.length > 1) {
                    const lastItem = splitTicketNumber[splitTicketNumber.length - 1];
                    if (lastItem === groupTicketsName) {
                        groupTickets = true;
                        ticketNumber = splitTicketNumber[0];
                    }
                }

                const ticketValue = { ticketNumber, groupTickets };

                if (
                    !isEqualLastUsedTicket(ticketValue) ||
                    (isEqualLastUsedTicket(ticketValue) && now.diff(lastUsedTicketDate, 'seconds') > 5)
                ) {
                    window.navigator.vibrate && window.navigator.vibrate(500);
                    check(ticketValue, CheckTypeEnum.Scan);
                }
            }
        },
        [isInputFocus, loading, error, isEqualLastUsedTicket, lastUsedTicketDate, check]
    );

    return {
        loading,
        success,
        error,
        successData,
        errorData,
        resetState,
        handleScan,
        check,
        isPhoneOrTablet,
        isInputFocus,
        setIsInputFocus,
    };
};

export default useCheck;
