import EnterQrCode from './components/EnterQrCode';
import QrReader from 'react-qr-reader';
import TicketSuccessStatus from './components/TicketSuccessStatus';
import TicketErrorStatus from './components/TicketErrorStatus';

import useCheck from './useCheck';
import './styles.sass';
import classNames from 'classnames';

const Tickets = () => {
    const {
        loading,
        success,
        successData,
        errorData,
        resetState,
        handleScan,
        check,
        isPhoneOrTablet,
        isInputFocus,
        setIsInputFocus,
    } = useCheck();

    return (
        <div className="check-wrap">
            {success && <TicketSuccessStatus successData={successData} onClose={resetState} />}
            {errorData && <TicketErrorStatus errorData={errorData} onClose={resetState} />}

            {isPhoneOrTablet && (
                <div className={classNames('qr-reader', isInputFocus && 'qr-reader--hide-area')}>
                    <QrReader className="qr-reader__scanner" onError={() => {}} delay={100} onScan={handleScan} />
                </div>
            )}
            <div className="qr-field">
                <EnterQrCode
                    loading={loading}
                    onSubmit={check}
                    onBlur={() => {
                        setIsInputFocus(false);
                    }}
                    onFocus={() => {
                        setIsInputFocus(true);
                    }}
                />
            </div>
        </div>
    );
};

export default Tickets;
