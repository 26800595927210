import { ErrorTicketStatus } from '../../../../../../types';
import { MD } from '../../../../../../config/constants';
import { useMediaQuery } from 'react-responsive';
import { NotificationErrorStatus } from './components/NotificationErrorStatus';
import { ScreenErrorStatus } from './components/ScreenErrorStatus';
import { DisplayTypeEnum } from './enums/display-type';

type Props = {
    onClose: () => void;
    errorData: ErrorTicketStatus | null;
};

const TicketErrorStatus = ({ errorData, onClose }: Props) => {
    const isMobileOrTable = useMediaQuery({ maxWidth: MD });

    if (errorData === null) {
        return null;
    }

    const { errorCause, errorDescription = '', displayType = '' } = errorData;

    const finalDisplayType = displayType
        ? displayType
        : isMobileOrTable
        ? DisplayTypeEnum.Screen
        : DisplayTypeEnum.Notification;

    switch (finalDisplayType) {
        case DisplayTypeEnum.Notification:
            return (
                <NotificationErrorStatus
                    errorDescription={errorDescription}
                    errorCause={errorCause}
                    onClose={onClose}
                />
            );
        case DisplayTypeEnum.Screen:
            return <ScreenErrorStatus errorDescription={errorDescription} errorCause={errorCause} onClose={onClose} />;
        default:
            return null;
    }
};

export default TicketErrorStatus;
