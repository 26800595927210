import { useState, useCallback, useEffect, useMemo } from 'react';
import { billingApi } from '../../../../lib/api';
import { ChartProps, FromTimeToTime, GetHistoryParams, HistoryDetail, HistoryDetails } from '../../../../types';
import { useMediaQuery } from 'react-responsive';
import { MD } from '../../../../config/constants';
import { groupChartData, mobileAndTabletCheck, roundToHour } from '../../../../lib/utils';
import { formatDate } from '../../../../lib/utils';
import notification from '../../../../components/Notification';
import { toast } from 'react-toastify';

const useTicketHistory = () => {
    const [historyData, setHistoryData] = useState<HistoryDetails | undefined>(undefined);
    const [activeTabId, setActiveTabId] = useState<number>(0);
    const [rangeDates, setRangeDates] = useState<FromTimeToTime>({
        from: null,
        to: null,
    });
    const [showRangeDate, toggleShowRangeDate] = useState<boolean>(false);
    const isPhoneOrTablet = useMediaQuery({ query: `(max-width: ${MD}px)` }) && mobileAndTabletCheck();

    const fetchData = async ({ from, to }: FromTimeToTime) => {
        try {
            if (from && to) {
                setRangeDates({ from, to });
                const params: GetHistoryParams = {
                    start: formatDate(from, 'DD-MM-YYYY'),
                    end: formatDate(to, 'DD-MM-YYYY'),
                };
                await billingApi.getHistory(params).then((historyData: HistoryDetails | undefined) => {
                    setHistoryData(historyData);
                });
            }
        } catch (err: any) {
            console.error(err);
            if (err?.message === 'RESPONSE_ERROR') {
                notification.error('Что-то пошло не так, попробуйте позже', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }
    };

    const hideRangeDate = useCallback(() => {
        if (showRangeDate) {
            if (isPhoneOrTablet) {
                document.body.style.overflow = 'auto';
            }
            toggleShowRangeDate(false);
        }
    }, [showRangeDate, isPhoneOrTablet]);

    const tabs = useMemo(
        () => [
            {
                id: 0,
                name: 'День',
                onClick: () => {
                    let from = new Date();
                    from.setHours(0, 0, 0, 0);
                    let to = roundToHour(new Date());

                    setActiveTabId(0);
                    fetchData({
                        from: from,
                        to: to,
                    });
                },
            },
            {
                id: 1,
                name: 'Неделя',
                onClick: () => {
                    let from = new Date();
                    from.setDate(from.getDate() - 6);
                    from.setHours(0, 0, 0, 0);

                    let to = roundToHour(new Date());

                    setActiveTabId(1);
                    fetchData({
                        from,
                        to,
                    });
                },
            },
            {
                id: 2,
                name: 'Месяц',
                onClick: () => {
                    let from = new Date();
                    from.setMonth(from.getMonth() - 1);
                    from.setHours(0, 0, 0, 0);

                    let to = roundToHour(new Date());

                    setActiveTabId(2);
                    fetchData({
                        from,
                        to,
                    });
                },
            },
            {
                id: 3,
                name: 'Год',
                onClick: () => {
                    let from = new Date();
                    from.setFullYear(from.getFullYear() - 1);
                    from.setHours(0, 0, 0, 0);

                    let to = roundToHour(new Date());

                    setActiveTabId(3);
                    fetchData({
                        from,
                        to,
                    });
                },
            },
            {
                id: 4,
                name: 'Свой период',
                onClick: () => {
                    if (isPhoneOrTablet) {
                        document.body.style.overflow = 'hidden';
                    }

                    setActiveTabId(4);
                    toggleShowRangeDate(true);
                },
            },
        ],
        [isPhoneOrTablet]
    );

    const chartProps: ChartProps = useMemo(() => {
        return groupChartData(historyData?.data, rangeDates, isPhoneOrTablet);
        // eslint-disable-next-line
    }, [historyData, isPhoneOrTablet]);

    //делаем reverse чтобы наверху была свежая информация
    const historyItems: HistoryDetail[] | undefined = useMemo(() => historyData?.data.reverse(), [historyData]);

    useEffect(() => {
        let from = new Date();
        from.setHours(0, 0, 0, 0);
        let to = roundToHour(new Date());
        fetchData({ from, to });
    }, []);

    return {
        chartProps,
        historyItems,
        fetchData,
        showRangeDate,
        hideRangeDate,
        tabs,
        activeTabId,
        isPhoneOrTablet,
    };
};

export default useTicketHistory;
