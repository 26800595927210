import React, { useState, useEffect } from 'react';

import Routes from './containers/routes/';
import AppContext from './context/app';
import { User } from './types';
import { userApi } from './lib/api';
import tokenManager from './lib/token-manager';
import { VVP } from './lib/viewport-fix';
import notification from './components/Notification';

const App = () => {
    const [profile, setProfile] = useState<User | null>(null);
    const removeZoomEventsInMobile = (e: Event) => {
        e.preventDefault();
    };

    useEffect(() => {
        const init = async () => {
            new VVP();
            document.addEventListener('gesturestart', removeZoomEventsInMobile);

            document.addEventListener('gesturechange', removeZoomEventsInMobile);

            document.addEventListener('gestureend', removeZoomEventsInMobile);

            if (await tokenManager.isTokenValid()) {
                try {
                    const profile = await userApi.getUserData();
                    setProfile(profile);
                } catch (err: any) {
                    if (err?.message === 'RESPONSE_ERROR') {
                        notification.error('Что-то пошло не так, попробуйте позже');
                    }
                }
            }
        };
        init();

        return () => {
            document.removeEventListener('gesturestart', removeZoomEventsInMobile);

            document.removeEventListener('gesturechange', removeZoomEventsInMobile);

            document.removeEventListener('gestureend', removeZoomEventsInMobile);
        };
    }, []);
    return (
        <AppContext.Provider
            value={{
                profile,
                setProfile,
            }}
        >
            <Routes />
        </AppContext.Provider>
    );
};

export default App;
