import { toast } from 'react-toastify';

const notification = {
    success(msg: string, options = {}) {
        return toast.success(msg, {
            // Merge additionals options
            ...options,
            className: 'notification notification--success',
        });
    },
    error(msg: string, options = {}) {
        return toast.error(msg, {
            ...options,
            className: 'notification notification--error',
        });
    },
};

export default notification;
