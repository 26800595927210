import { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { routes } from '../../../../config/constants';
import { signOut } from '../../../../lib/utils';

import appContent from '../../../../context/app';
import { mobileAndTabletCheck } from '../../../../lib/utils';

import imgLogo from '../../../../assets/images/logo.svg';
import { ReactComponent as IconLogOut } from '../../../../assets/images/icons/ic_log_out.svg';
import { ReactComponent as IconCamera } from '../../../../assets/images/icons/ic_camera.svg';
import { ReactComponent as IconPage } from '../../../../assets/images/icons/ic_page.svg';
import { ReactComponent as IconQuestion } from '../../../../assets/images/icons/ic_question.svg';
import { ReactComponent as IconCross } from '../../../../assets/images/icons/ic_cross.svg';
import { ReactComponent as IconNote } from '../../../../assets/images/icons/ic_note.svg';
import { MD } from '../../../../config/constants';
import { useMediaQuery } from 'react-responsive';
import { phoneCallback } from '../../../../config/constants';
import './styles.sass';

type AsidePropsType = {
    asidePanelIsShow: boolean;
    hideAsidePanel: () => void;
};

const AsidePanel = ({ asidePanelIsShow, hideAsidePanel }: AsidePropsType) => {
    const { profile } = useContext(appContent);
    const isPhoneOrTablet = useMediaQuery({ query: `(max-width: ${MD}px)` }) && mobileAndTabletCheck();

    useEffect(() => {
        if (asidePanelIsShow && !document.documentElement.classList.contains('fixed')) {
            document.documentElement.classList.add('fixed');
        } else {
            document.documentElement.classList.remove('fixed');
        }
    }, [asidePanelIsShow]);

    return (
        <div className={classNames('aside-panel', asidePanelIsShow && 'visible')}>
            <div className="aside-panel__body-header">
                <div className="aside-panel__body-logo">
                    <img alt="logo" src={imgLogo} className="sider__logo" />
                </div>
                <div className="aside-panel__body-close-button" onClick={() => hideAsidePanel()}>
                    <IconCross />
                </div>
            </div>
            <div className="aside-panel__body">
                <nav className="aside-panel__body-nav">
                    <NavLink
                        to={routes.roomTickets}
                        onClick={hideAsidePanel}
                        className="aside-panel__body-nav-item"
                        activeClassName="active"
                    >
                        {isPhoneOrTablet ? (
                            <>
                                <IconCamera />
                                <span>Сканировать</span>
                            </>
                        ) : (
                            <>
                                <IconNote />
                                <span>Ввести номер билета</span>
                            </>
                        )}
                    </NavLink>
                    <NavLink
                        to={routes.roomHistory}
                        onClick={hideAsidePanel}
                        className="aside-panel__body-nav-item"
                        activeClassName="active"
                    >
                        <IconPage />
                        <span>История</span>
                    </NavLink>
                    <div className="aside-panel__body-nav-item active">
                        <IconQuestion />
                        <div className="aside-panel__support-item">
                            <div className="aside-panel__support-item-text">Техническая поддержка</div>
                            <a href={`tel:${phoneCallback}`} className="aside-panel__support-item-link">
                                {phoneCallback}
                            </a>
                        </div>
                    </div>
                </nav>

                <div className="aside-panel__body-footer">
                    <div className="aside-panel__body-organization">{profile?.organization?.name}</div>
                    <div className="aside-panel__body-signout">
                        <button onClick={signOut} type="button" className="aside-panel__body-signout-button">
                            <span>Выйти из аккаунта</span>
                            <IconLogOut />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AsidePanel;
