import { useCallback, useState } from 'react';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import MaskedInput from 'react-text-mask';
import { mobileAndTabletCheck, useDisablerScrollerForIOS } from '../../../../../../lib/utils';
import { MD } from '../../../../../../config/constants';
import { useMediaQuery } from 'react-responsive';
import { FieldCheckbox, FieldInput } from '../../../../../../components/FieldsForm';
import './styles.sass';
import { ICheckValue } from '../../types/check-value';
import { CheckTypeEnum } from '../../enums/check-type';

type FormikData = {
    ticket: string;
    isGroupTickets: boolean;
};

type EnterQrCodeProps = {
    loading: boolean;
    onSubmit: (ticket: ICheckValue, checkType: CheckTypeEnum, resetForm: Function, setError: Function) => void;
    onFocus: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const EnterQrCode = ({ loading, onSubmit, onFocus, onBlur }: EnterQrCodeProps) => {
    const [showBtn, toggleShowBtn] = useState<boolean>(false);
    const isPhoneOrTablet = useMediaQuery({ query: `(max-width: ${MD}px)` }) && mobileAndTabletCheck();

    useDisablerScrollerForIOS();

    const onSignIn = useCallback(
        async ({ ticket, isGroupTickets }: FormikData, formikProps: FormikHelpers<FormikData>) => {
            onSubmit(
                { ticketNumber: ticket, groupTickets: isGroupTickets },
                CheckTypeEnum.Form,
                formikProps.resetForm,
                formikProps.setErrors
            );
        },
        [onSubmit]
    );

    const initialValues = {
        ticket: '',
        isGroupTickets: false,
    };

    return (
        <div className="enter-qr-code">
            <Formik
                onSubmit={onSignIn}
                initialValues={initialValues}
                validationSchema={getValidationSchema()}
                enableReinitialize={true}
            >
                {({ handleSubmit }: FormikProps<FormikData>) => (
                    <form onSubmit={handleSubmit} className="enter-qr-code__form" data-test-target="enter-qr-form">
                        <div className="enter-qr-code__field enter-qr-code__field--grid-template-1">
                            <FieldInput
                                name="ticket"
                                placeholder="000000"
                                label={isPhoneOrTablet ? 'Или введите номер билета' : 'Введите номер билета'}
                                disabled={loading}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                autoFocus={false}
                                renderComponent={(field) => (
                                    <MaskedInput
                                        {...field}
                                        guide={false}
                                        pipe={(value: string) => {
                                            value.length > 0 ? toggleShowBtn(true) : toggleShowBtn(false);
                                            return value;
                                        }}
                                        mask={(s) => Array.from(s).map(() => /.*/)}
                                    />
                                )}
                            />
                        </div>

                        <div className="enter-qr-code__field enter-qr-code__field--grid-template-2">
                            <FieldCheckbox name="isGroupTickets" label="Групповой билет" />
                        </div>

                        {showBtn && (
                            <div className="enter-qr-code__btn-wrap">
                                <button type="submit" disabled={loading} className="enter-qr-code__btn">
                                    Найти
                                </button>
                            </div>
                        )}
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default EnterQrCode;

const getValidationSchema = () =>
    objectYup().shape({
        ticket: stringYup().required('Введите код'),
    });
