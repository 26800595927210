import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { routes } from '../../config/constants';
import SignIn from '../signin';
import ScrollToTop from './scroll-to-top';
import ProtectedRoute from './protected';
import Toast from '../../components/Toast';
import './styles.sass';

const Routes = () => {
    return (
        <div className="app-wrap">
            <BrowserRouter>
                <ScrollToTop />
                <Switch>
                    <Route path={routes.signin} component={SignIn} />
                    <Route path={routes.main} component={ProtectedRoute} />
                </Switch>
            </BrowserRouter>
            <Toast />
        </div>
    );
};

export default Routes;
