import { FC } from 'react';
import { HistoryDetail } from '../../../../../../types';
import { ReactComponent as IconError } from '../../../../../../assets/images/icons/ic_cross_circle.svg';
import { ReactComponent as IconSuccess } from '../../../../../../assets/images/icons/ic_check_circle.svg';
import { formatDate } from '../../../../../../lib/utils';
import './styles.sass';

type ListProps = {
    data: HistoryDetail[] | undefined;
};

const List: FC<ListProps> = ({ data }) => {
    return (
        <div className="history-list">
            {data?.map((trow: HistoryDetail, id: number) => (
                <div className="history-list__item" key={id}>
                    <div className="history-list__item-icon">
                        {trow.status === 'USED' ? <IconSuccess /> : <IconError />}
                    </div>
                    <div className="history-list__item-content">
                        <div className="history-list__item-common-info">
                            <div className="history-list__item-ticket-num">{trow.ticketNumber}</div>
                            <div className="history-list__item-ticket-date">
                                {formatDate(trow.createdDate, 'DD.MM.YYYY, HH:mm')}
                            </div>
                        </div>
                        <div className="history-list__item-ticket-info">{trow.tariffName}</div>
                        <div className="history-list__item-ticket-info">{trow.productName}</div>
                        {trow.status !== 'USED' && (
                            <div className="history-list__item-ticket-status">{trow.statusDescription}</div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default List;
