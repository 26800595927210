import { Checkbox } from '@russpass/ui';
import { Field, FieldProps } from 'formik';

interface IFieldCheckboxProps {
    name: string;
    label: string;
}

export const FieldCheckbox = ({ name, label }: IFieldCheckboxProps) => {
    return (
        <Field name={name}>
            {(props: FieldProps) => {
                const {
                    field: { value, name: formikName },
                    form: { setFieldValue },
                } = props;

                const onChange = (checked: boolean) => {
                    setFieldValue(formikName, checked);
                };

                return (
                    <Checkbox label={label} id={name} checked={value} fieldName={formikName} handleChange={onChange} />
                );
            }}
        </Field>
    );
};
