import { useCallback, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import { object as objectYup, string as stringYup } from 'yup';
import MaskedInput from 'react-text-mask';

import { authApi, userApi } from '../../lib/api';
import tokenManager from '../../lib/token-manager';
import { phoneCallback, routes } from '../../config/constants';
import appContext from '../../context/app';
import { FieldInput } from '../../components/FieldsForm';
import { regUpperSymbols } from '../../lib/utils';
import imgLogo from '../../assets/images/logo.svg';

import './styles.sass';

type FormikData = {
    code: string;
};

const SignIn = () => {
    const [loading, setLoading] = useState(false);
    //чтобы показывать серым цветом ошибку
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const history = useHistory();
    const { setProfile } = useContext(appContext);

    const onSignIn = useCallback(
        async (values: FormikData, formikProps: FormikHelpers<FormikData>) => {
            const code = Object.values(values).join('').toUpperCase();

            try {
                setLoading(true);
                const token = await authApi.signIn(code);
                tokenManager.setToken(token);
                const profile = await userApi.getUserData();
                setProfile(profile);
                history.push(routes.roomTickets);
            } catch (err: any) {
                if (err?.errorCode === '1016') {
                    setErrorMsg('Неправильный код');

                    formikProps.setErrors({
                        code: 'Неправильный код',
                    });
                } else if ((err?.errorCode !== '1016' && err?.errorCode === '0') || err?.message === 'RESPONSE_ERROR') {
                    setErrorMsg('Что-то пошло не так, попробуйте позже');

                    formikProps.setErrors({
                        code: 'Что-то пошло не так, попробуйте позже',
                    });
                }
                setError(true);

                setLoading(false);
            }
        },
        [setProfile, history]
    );

    useEffect(() => {
        const checkAuth = async () => {
            if (await tokenManager.isTokenValid()) {
                history.push(routes.roomTickets);
            }
        };
        checkAuth();
    }, [history]);

    return (
        <div className="signin">
            <div className="signin__header">
                <div className="signin__header-logo">
                    <img alt="logo" src={imgLogo} className="signin__logo" />
                </div>
                <div className="signin__header-title" data-test-target="login-title">
                    RUSSPASS Scanner
                </div>
                <div className="signin__header-logo"></div>
            </div>
            <div className="signin__form-wrap">
                <Formik
                    onSubmit={onSignIn}
                    initialValues={{
                        code: '',
                    }}
                    validationSchema={getValidationSchema()}
                    enableReinitialize={true}
                >
                    {({ handleSubmit }: FormikProps<FormikData>) => (
                        <form onSubmit={handleSubmit} className="signin__form" data-test-target="login-form">
                            <div className="signin__form-field">
                                <FieldInput
                                    name="code"
                                    placeholder="000000"
                                    label="Введите код для входа в аккаунт"
                                    disabled={loading}
                                    autoFocus={true}
                                    hint={error ? errorMsg : ''}
                                    renderComponent={(field) => (
                                        <MaskedInput
                                            {...field}
                                            guide={false}
                                            pipe={(value: string) => {
                                                // !error && setError(false);
                                                return value.toUpperCase();
                                            }}
                                            mask={(s) => Array.from(s).map(() => regUpperSymbols)}
                                        />
                                    )}
                                />
                            </div>

                            <div className="signin__btn-wrap">
                                <button type="submit" disabled={loading} className="signin__btn">
                                    Войти
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>

            <div className="signin__footer">
                <div className="signin__footer-text">Техническая поддержка RUSSPASS</div>
                <a href={`tel:${phoneCallback}`} className="signin__footer-link">
                    {phoneCallback}
                </a>
            </div>
        </div>
    );
};

export default SignIn;

const getValidationSchema = () =>
    objectYup().shape({
        code: stringYup()
            .matches(regUpperSymbols, {
                message: 'Неправильный код',
            })
            .required('Введите код'),
    });
