import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import CustomLegend from './components/Legend';
import CustomTooltip from './components/Tooltip';
import { FC, memo } from 'react';
import { ChartProps } from '../../../../../../types';

import './styles.sass';

const HistoryChart: FC<ChartProps> = memo(({ data, ticks, isPhone }) => {
    return (
        <div className="history-chart">
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart width={500} height={200} data={data} margin={{ top: 0, right: 35, bottom: 0, left: 7 }}>
                    <defs>
                        <linearGradient id="ChartGradient" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#007470" stopOpacity={0.8} />
                            <stop offset="100%" stopColor="#007470" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="4 4" />
                    <Legend content={<CustomLegend />} verticalAlign="top" />
                    <XAxis
                        dataKey="time"
                        tick={{ fontSize: '14px', fill: 'rgba(29, 29, 29, 0.5)' }}
                        textAnchor="middle"
                        tickMargin={12}
                        ticks={ticks}
                        minTickGap={isPhone ? 30 : 50}
                        tickLine={false}
                    />
                    <YAxis
                        tick={{ fontSize: '14px', fill: 'rgba(29, 29, 29, 0.5)', textAnchor: 'middle' }}
                        width={45}
                        tickMargin={20}
                        tickSize={5}
                        tickLine={false}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Area type="linear" dataKey="Использован" stroke="#007470" fill="url(#ChartGradient)" />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
});

export default HistoryChart;
