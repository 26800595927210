import { FC } from 'react';
import { HistoryDetail } from '../../../../../../types';
import classNames from 'classnames';
import { formatDate } from '../../../../../../lib/utils';
import './styles.sass';

type TableProps = {
    data: HistoryDetail[] | undefined;
};

const Table: FC<TableProps> = ({ data }) => {
    return (
        <div className="table">
            <div className="table__head">
                <div className="table__row">
                    <div className="table__ceil">Номер билета</div>
                    <div className="table__ceil">Дата</div>
                    <div className="table__ceil">Статус</div>
                    <div className="table__ceil">Услуга</div>
                    <div className="table__ceil">Тариф</div>
                </div>
            </div>
            <div className="table__body">
                {data?.map((trow: HistoryDetail, id: number) => (
                    <div className="table__row" key={id}>
                        <div className="table__ceil">{trow.ticketNumber}</div>
                        <div className="table__ceil">{formatDate(trow.createdDate, 'DD.MM.YYYY, HH:mm')}</div>
                        <div
                            className={classNames(
                                'table__ceil',
                                trow.status === 'USED' ? 'table__ceil--used-color' : 'table__ceil--unused-color'
                            )}
                        >
                            {trow.statusDescription}
                        </div>
                        <div className="table__ceil table__ceil--text-nowrap">{trow.productName}</div>
                        <div className="table__ceil">{trow.tariffName}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Table;
