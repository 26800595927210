import HistoryTabs from './components/Tabs';
import HistoryChart from './components/Chart';
import './styles.sass';
import useTicketHistory from './useTicketHistory';
import SelectRangeDate from './components/SelectRangeDate';
import Table from './components/Table';
import List from './components/List';

const History = () => {
    const { chartProps, historyItems, fetchData, showRangeDate, hideRangeDate, tabs, activeTabId, isPhoneOrTablet } =
        useTicketHistory();

    return (
        <div className="history">
            <div className="history-wrap">
                <HistoryTabs tabs={tabs} activeTabId={activeTabId} />
                {showRangeDate && <SelectRangeDate hide={hideRangeDate} getData={fetchData} />}
                {chartProps && <HistoryChart {...chartProps} />}
                {isPhoneOrTablet ? <List data={historyItems} /> : <Table data={historyItems} />}
            </div>
        </div>
    );
};

export default History;
