import isEqual from 'lodash/isEqual';

import { mapToken, setCookie, deleteCookie, getCookie, signOut } from './utils';
import { authBasicToken, apiUrl, apiService, cookieNames } from '../config/constants';
import { BackendTokenResponse } from '../types';

export type Token = {
    accessToken: string;
    expires: Date;
    refreshToken: string;
};

type TokenManager = {
    isTokenValid: () => Promise<boolean> | boolean;
    getToken: () => Token | null;
    setToken: (token: Token) => void;
    clearToken: () => void;
    overdueToken: () => void;
};

const initialTokenManager = Object.freeze({
    isTokenValid: async () => false,
    getToken: () => null,
    setToken: () => {},
    clearToken: () => {},
    overdueToken: () => {},
});

let tokenManager: TokenManager = initialTokenManager;

let refreshingTokenPromise: Promise<boolean> | null = null;

export const http = <T>(req: RequestInfo, params: RequestInit): Promise<T> =>
    fetch(req, params)
        .then((res) => {
            if (res.ok) {
                return res.json();
            } else throw new Error('request failed');
        })
        .catch((err) => {
            throw new Error(err);
        });

const refreshToken = async function (token: string) {
    try {
        const newToken = await http<BackendTokenResponse>(
            `${apiUrl}${apiService.auth}/oauth/token?grant_type=refresh_token&refresh_token=${token}`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Basic ${authBasicToken}`,
                },
            }
        );
        return mapToken(newToken);
    } catch (err) {
        throw new Error('Error with refresh token');
    }
};

const manager = (initialToken: Token | null): TokenManager => {
    // Токен авторизации
    // let token = initialToken;
    // Получение токена из мемоизированной переменной или куки
    const getToken = () => {
        // if (token) return token;
        const cookieToken = getCookie(cookieNames.token);
        if (cookieToken) {
            return JSON.parse(cookieToken);
        }
        return null;
    };

    const setToken = (newToken: Token | null) => {
        // token = newToken;
        if (newToken) {
            setCookie(cookieNames.token, JSON.stringify(newToken));
        }
    };

    const isTokenValid = async (): Promise<boolean> => {
        try {
            if (!refreshingTokenPromise) {
                refreshingTokenPromise = new Promise((resolve, reject) => {
                    const time = new Date();
                    const token = getToken();
                    let isValidCurrentToken = !!token && token.expires && time < new Date(token.expires);
                    if (!isValidCurrentToken && token && token.refreshToken) {
                        return refreshToken(token.refreshToken)
                            .then(async (newToken) => {
                                setToken(newToken);
                                refreshingTokenPromise = null;
                                resolve(await isTokenValid());
                            })
                            .catch((refreshTokenError) => {
                                signOut();
                                refreshingTokenPromise = null;
                                reject(refreshTokenError);
                            });
                    }
                    resolve(isValidCurrentToken);
                });
            }
            return refreshingTokenPromise.then((isValid) => {
                refreshingTokenPromise = null;
                return isValid;
            });
        } catch (err) {
            console.error(err);
            return false;
        }
    };

    return {
        /**
         * Проверка истечения времени действия токена
         */
        isTokenValid,
        // Получение токена
        getToken,
        // Обновление токена
        setToken,
        clearToken: () => {
            // token = null;
            deleteCookie(cookieNames.token);
        },
        overdueToken: () => {
            const current = new Date();
            setToken({
                ...getToken(),
                expires: current.setHours(current.getHours() - 2),
            });
        },
    };
};

if (isEqual(tokenManager, initialTokenManager)) {
    tokenManager = manager(null);
}

export default tokenManager;
