import { Route, Switch, Redirect } from 'react-router-dom';
import { useState } from 'react';
import { routes, MD } from '../../config/constants';
import { useMediaQuery } from 'react-responsive';
import Check from './containers/Check';
import History from './containers/History';
import AsidePanel from './components/AsidePanel';
import CommonLayout from './components/CommonLayout';
import { mobileAndTabletCheck } from '../../lib/utils';
import DownloadButton from './containers/History/components/DownloadButton';

const Room = () => {
    const [asidePanelIsShow, toggleAsidePanel] = useState(false);
    const isPhoneOrTablet = useMediaQuery({ query: `(max-width: ${MD}px)` }) && mobileAndTabletCheck();

    function hideAsidePanel() {
        if (asidePanelIsShow) {
            toggleAsidePanel(false);
        }
    }

    function showAsidePanel() {
        if (!asidePanelIsShow) {
            toggleAsidePanel(true);
        }
    }

    return (
        <div className="room-layout">
            <AsidePanel asidePanelIsShow={asidePanelIsShow} hideAsidePanel={hideAsidePanel} />
            <Switch>
                <Route
                    path={routes.roomTickets}
                    render={() => {
                        return (
                            <CommonLayout
                                showAsidePanel={showAsidePanel}
                                title={isPhoneOrTablet ? '' : 'Ввести номер билета'}
                                reverseColors={isPhoneOrTablet}
                            >
                                <Check />
                            </CommonLayout>
                        );
                    }}
                />
                <Route
                    path={routes.roomHistory}
                    render={() => {
                        return (
                            <CommonLayout
                                showAsidePanel={showAsidePanel}
                                title="История"
                                HeaderRightButton={DownloadButton}
                                hideHeaderBorder={true}
                            >
                                <History />
                            </CommonLayout>
                        );
                    }}
                />
                <Redirect to={routes.roomTickets} />
            </Switch>
        </div>
    );
};

export default Room;
