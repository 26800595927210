// Адрес API
const envApiUrl = process.env.REACT_APP_API_URL; // .env
//@ts-ignore
const dynamicEnvApiUrl = window?._ENV_?.API_URL; // ConfigMap k8s
export const apiUrl = dynamicEnvApiUrl ?? envApiUrl;

// Токен для авторизации
const envAuthBasicToken = process.env.REACT_APP_AUTH_BASIC_TOKEN; // .env
//@ts-ignore
const dynamicEnvAuthBasicToken = window?._ENV_?.AUTH_BASIC_TOKEN; // ConfigMap k8s
export const authBasicToken = dynamicEnvAuthBasicToken ?? envAuthBasicToken;

// ID клиента для авторизации
const envClientId = process.env.REACT_APP_CLIENT_ID; // .env
//@ts-ignore
const dynamicEnvClientId = window?._ENV_?.CLIENT_ID; // ConfigMap k8s
export const clientId = dynamicEnvClientId ?? envClientId;

// секретная фраза для авторизации
const envAuthSecret = process.env.REACT_APP_AUTH_SECRET; // .env
//@ts-ignore
const dynamicEnvAuthSecret = window?._ENV_?.AUTH_SECRET; // ConfigMap k8s
export const authSecret = dynamicEnvAuthSecret ?? envAuthSecret;

//   телефон обратной связи
export const phoneCallback = '8 800 300-61-22';

export const routes = {
    main: '/',
    signin: '/signin',
    roomTickets: '/tickets',
    roomHistory: '/history',
};

/**
 * Роуты сервисов для работы с api
 */
export const apiService = {
    user: '/user',
    auth: '/sso',
    billing: '/billingapi/rest',
};

export const cookieNames = {
    token: 'auth-token',
};

/**
 * Breakpoints
 */
export const XS = 320;
export const SM = 360;
export const MD = 904;
export const LG = 1320;
