import { fetchRequest } from './index';
import { apiService } from '../../config/constants';
import { HistoryDetails, GetHistoryParams, IProcessingTickets } from '../../types';
// import { isObject } from '../utils';
// import { isOfType } from '../../types/utils';

// const dateISOStringRegexp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3}Z)?/;

export const setVisit = async (ticketNumber: string, groupTickets: boolean = false) => {
    const response: IProcessingTickets = await fetchRequest.post(
        `${apiService.billing}/ticket/spend`,
        { ticketNumber, groupTickets },
        {
            isProtected: true,
        }
    );
    if (response) {
        return response;
    }
    throw new Error('Не удалось получить билет');
};

export const getReport = async () => {
    const response: Blob = await fetchRequest.get(`${apiService.billing}/ticket/use-report`, null, {
        isProtected: true,
    });
    if (response) {
        return response;
    }
};

export const getHistory = async (params: GetHistoryParams) => {
    const response: HistoryDetails = await fetchRequest.get(`${apiService.billing}/ticket/use-history`, params, {
        isProtected: true,
    });

    if (response) {
        return response;
    }
};

/* eslint-disable import/no-anonymous-default-export */
export default {
    setVisit,
    getReport,
    getHistory,
};
