import { ReactNode, FC } from 'react';
import { ReactComponent as IconBurger } from '../../../../assets/images/icons/ic_burger_menu.svg';
import classNames from 'classnames';

import './styles.sass';

type CommonLayoutPropsType = {
    title: string;
    children: ReactNode;
    showAsidePanel: () => void;
    reverseColors?: boolean;
    HeaderRightButton?: FC;
    hideHeaderBorder?: boolean;
};

const CommonLayout = ({
    title,
    children,
    showAsidePanel,
    reverseColors,
    HeaderRightButton,
    hideHeaderBorder = false,
}: CommonLayoutPropsType) => {
    return (
        <div className="common-layout">
            <div
                className={classNames(
                    'common-layout__header',
                    reverseColors && 'common-layout__header--reverse-colors',
                    hideHeaderBorder && 'common-layout__header--hide-border'
                )}
            >
                <div className="common-layout__header-button">
                    <div className="button common-layout__header-button-burger" onClick={showAsidePanel}>
                        <IconBurger />
                    </div>
                </div>
                <div className="common-layout__header-title">{title}</div>
                <div className="common-layout__header-button">{HeaderRightButton && <HeaderRightButton />}</div>
            </div>
            <div className="common-layout__wrap">{children}</div>
        </div>
    );
};

export default CommonLayout;
