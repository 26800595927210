import { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { routes } from '../../config/constants';
import Room from '../room';
import tokenManager from '../../lib/token-manager';

const ProtectedRoute = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAuth = async () => {
            if (!(await tokenManager.isTokenValid())) {
                history.push(routes.signin);
            } else {
                setLoading(false);
            }
        };
        checkAuth();
    }, [history]);
    if (loading) {
        return null;
    }
    return (
        <Switch>
            <Route path={routes.main} component={Room} />
        </Switch>
    );
};

export default ProtectedRoute;
