import { cssTransition, ToastContainer } from 'react-toastify';
import './styles.sass';

const Toast = () => {
    return (
        <ToastContainer
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            limit={1}
            icon={false}
            draggable
            pauseOnHover
            toastClassName="notification"
            bodyClassName="notification__body"
            pauseOnFocusLoss={false}
            transition={cssTransition({
                enter: 'fade-in',
                exit: 'fade-out',
            })}
        />
    );
};

export default Toast;
