/* eslint-disable no-useless-escape */
import { BackendTokenResponse, ChartData, ChartProps, FromTimeToTime, HistoryDetail } from '../../types';
import { cookieNames, routes } from '../../config/constants';
import { RefObject, useEffect } from 'react';
import moment, { unitOfTime } from 'moment';

export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

// Работа с рефреш токеном, находится здесь, чтобы не создавать циклических импортов
export const mapToken = ({ access_token, expires_in, refresh_token }: BackendTokenResponse) => ({
    accessToken: access_token,
    expires: dateAdd(new Date(), 'second', expires_in) as Date,
    refreshToken: refresh_token,
});

/**
 * Adds time to a date. Modelled after MySQL DATE_ADD function.
 * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
 * https://stackoverflow.com/a/1214753/18511
 *
 * @param date  Date to start with
 * @param interval  One of: year, quarter, month, week, day, hour, minute, second
 * @param units  Number of units of the given interval to add.
 */
export function dateAdd(
    date: Date,
    interval: 'year' | 'quarter' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second',
    units: number
) {
    if (!(date instanceof Date)) return undefined;
    var ret: Date | undefined = new Date(date); //don't change original date
    const checkRollover = function () {
        // eslint-disable-next-line eqeqeq
        if (ret && ret.getDate() != date.getDate()) {
            ret.setDate(0);
        }
    };
    switch (String(interval).toLowerCase()) {
        case 'year':
            ret.setFullYear(ret.getFullYear() + units);
            checkRollover();
            break;
        case 'quarter':
            ret.setMonth(ret.getMonth() + 3 * units);
            checkRollover();
            break;
        case 'month':
            ret.setMonth(ret.getMonth() + units);
            checkRollover();
            break;
        case 'week':
            ret.setDate(ret.getDate() + 7 * units);
            break;
        case 'day':
            ret.setDate(ret.getDate() + units);
            break;
        case 'hour':
            ret.setTime(ret.getTime() + units * 3600000);
            break;
        case 'minute':
            ret.setTime(ret.getTime() + units * 60000);
            break;
        case 'second':
            ret.setTime(ret.getTime() + units * 1000);
            break;
        default:
            ret = undefined;
            break;
    }
    return ret;
}

export function setCookie(name: string, value: any, duration = 30) {
    let expires = '';
    if (duration) {
        var date = new Date();
        date.setTime(date.getTime() + duration * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = `${name}=${value}${expires}; path=/;`;
}

// возвращает куки с указанным name,
// или undefined, если ничего не найдено
export function getCookie(name: string) {
    let matches = document.cookie.match(
        new RegExp('(?:^|; )' + name.replace(/([/\\.$?*|{}/\\(/\\)/\\[\]\\/\\//\\+^])/g, '\\$1') + '=([^;]*)')
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function deleteCookie(name: string) {
    setCookie(name, '', -1);
}

export function signOut() {
    deleteCookie(cookieNames.token);
    window.location.href = routes.signin;
}

export const isObject = (data: any) => Object.prototype.toString.call(data) === '[object Object]';

/* Добавляем соответствующее количество минут, дабы привести к локальной таймзоне */
/* Для GMT+0300 getTimezoneOffset вернет -180 минут */
export const mapDate = (date: Date | string) =>
    dateAdd(new Date(date), 'minute', -new Date().getTimezoneOffset()) as Date;

export const mobileAndTabletCheck = function () {
    let check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor);
    return check;
};

export function isIOS() {
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
}

export const regUpperSymbols = /[A-Za-z0-9]/;

//Hack for iphone disable scroll event when input focus
//https://stackoverflow.com/questions/60797340/ios-safari-prevent-or-control-scroll-on-input-focus
export const useDisablerScrollerForIOS = () => {
    const disablerIOSScroll = () => {
        if (document.documentElement.scrollTop > 50) {
            document.documentElement.scrollTop = 0;
        }
    };

    useEffect(() => {
        isIOS() && document.addEventListener('scroll', disablerIOSScroll);

        return () => {
            isIOS() && document.removeEventListener('scroll', disablerIOSScroll);
        };
    });
};

export const useClosePopupOver = (wrapperRef: RefObject<HTMLDivElement> | null, callback: Function) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent): void => {
            if (wrapperRef?.current && !wrapperRef.current?.contains(event!.target as Node)) {
                callback();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [callback, wrapperRef]);
};

export const isToday = (someDate: Date | null) => {
    if (someDate) {
        const today = new Date();
        return (
            someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()
        );
    } else return false;
};

export const formatDate = (date: Date | string, format: string) => moment(new Date(date)).format(format);

export const roundToHour = (date: Date): Date => {
    let p = 60 * 60 * 1000; // milliseconds in an hour
    return new Date(Math.round(date.getTime() / p) * p);
};

export const groupChartData = (
    chartData: HistoryDetail[] | undefined,
    { from, to }: FromTimeToTime,
    isPhoneOrTablet: boolean
): ChartProps => {
    let data: ChartData[] = [];
    let fromMoment = moment(from);
    let toMoment = moment(to);
    let diffDays = toMoment.diff(fromMoment, 'day') + 1;
    let diffHours = toMoment.diff(fromMoment, 'hour') + 1;
    let diffTick: {
        count: number;
        type: unitOfTime.Base;
    } = {
        count: 0,
        type: 'day',
    };
    let formatDate: string;

    if (diffDays > 1) {
        formatDate = 'DD.MM.YY';
        diffTick.count = Math.floor(diffDays / 3);
        diffTick.type = 'day';
        for (let i = 0; i < diffDays; i++) {
            data.push({
                time: fromMoment.clone().add(i, 'day').format(formatDate),
                Использован: 0,
            });
        }
    } else {
        formatDate = 'HH:mm';
        diffTick.count = Math.floor(diffHours / 3);
        diffTick.type = 'hour';
        for (let i = 0; i < diffHours; i++) {
            if (i !== diffHours - 1) {
                data.push({
                    time: fromMoment.clone().add(i, 'hour').format(formatDate),
                    Использован: 0,
                });
            } else {
                data.push({
                    time: toMoment.clone().format(formatDate),
                    Использован: 0,
                });
            }
        }
    }

    if (chartData) {
        for (let i = 0; i < chartData.length; i++) {
            let findIndex = data.findIndex(
                (el: ChartData) =>
                    el.time === moment(chartData[i].createdDate).startOf('hour').format(formatDate) &&
                    chartData[i].status === 'USED'
            );

            if (findIndex !== -1) {
                data[findIndex].Использован += 1;
            }
        }
    }

    return {
        data: data,
        ticks: isPhoneOrTablet
            ? [
                  fromMoment.format(formatDate),
                  fromMoment.clone().add(diffTick.count, diffTick.type).format(formatDate),
                  fromMoment
                      .clone()
                      .add(diffTick.count * 2, diffTick.type)
                      .format(formatDate),
                  toMoment.format(formatDate),
              ]
            : [],
        isPhone: isPhoneOrTablet,
    };
};
