import { LegendProps } from 'recharts';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import './styles.sass';

const CustomLegend = ({ payload }: LegendProps) => {
    return (
        <ul className="history-chart__legend">
            {payload?.map((entry: Payload, index: any) => (
                <li key={`item-${index}`} className="history-chart__legend-item">
                    <span className="history-chart__legend-circle" style={{ backgroundColor: entry.color }}></span>
                    {entry.value}
                </li>
            ))}
        </ul>
    );
};

export default CustomLegend;
